export default class Element {
  /**
   * Element is a collection type
   */
  public static isCollection(type: string) {
    switch (type) {
      case 'adverse_event':
      case 'collection':
      case 'data_grid':
      case 'table': {
        return true;
      }
    }

    return false;
  }

  /**
   * Element is a date type
   */
  public static isDate(type: string) {
    switch (type) {
      case 'date':
      case 'datetime':
      case 'time': {
        return true;
      }
    }

    return false;
  }

  /**
   * Element is a functional type
   */
  public static isFunctional(type: string) {
    switch (type) {
      case 'dicom_button':
      case 'close_observation_period':
      case 'randomization': {
        return true;
      }
    }

    return false;
  }

  /**
   * Element value format
   */
  public static valueFormat(value: any): any {
    return Array.isArray(value) ? value : [value];
  }
}
