import {
    StyledDrawer,
    StyledDrawerContentWrapper,
    StyledListItem,
    StyledListLinkWrapper,
} from "./DashboardSidebarBar.styled";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import avatarPNG from "commons/assets/avatar.png";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import {useLocation} from "react-router-dom";
import {Link} from "react-router-dom";
import {getRoleByRoleId} from "commons/constants/ditionaries";

type RouterLink = {
    variant: "routerLink";
    label: string;
    to: string;
    renderBottomLine?: boolean;
    isShowing?: boolean;
    subLabel?: string;
};
type PdfLink = {
    variant: "pdfLink";
    label: string;
    pdfItem: string;
    renderBottomLine?: boolean;
    isShowing?: boolean;
    subLabel?: string;
    to?: string;
};

export type SidebarItem = RouterLink | PdfLink;

export interface DashboardSidebarBarProps {
    isOpen: boolean;
    onClose: () => void;
    userName: string;
    userEmail: string;
    userRole: string;
    sidebarItemsData: SidebarItem[];
}

const DashboardSidebarBar = ({
    isOpen,
    onClose,
    userName,
    userEmail,
    userRole,
    sidebarItemsData,
}: DashboardSidebarBarProps) => {
    const location = useLocation();
    return (
        <StyledDrawer
            anchor="left"
            open={isOpen}
            onClose={onClose}
            variant="temporary"
        >
            <StyledDrawerContentWrapper>
                <Box pt={3} pl={2} pr={2} pb={2}>
                    <Avatar variant="circular" src={avatarPNG}/>

                    <Box pt={1.5}>
                        <Typography variant="overline" display="block">
                            {userName}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" display="block">
                            {userEmail}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            {getRoleByRoleId(userRole)}
                        </Typography>
                    </Box>
                    <Box pt={3}>
                        <Divider/>
                    </Box>
                    <List>
                        {sidebarItemsData
                            .filter((menuItem) => menuItem.isShowing)
                            .map((item) => (
                                <StyledListItem key={item.label}>
                                    <StyledListLinkWrapper
                                        $isActive={
                                            item.variant === "routerLink"
                                                ? location.pathname.includes(item.to)
                                                : undefined
                                        }
                                    >
                                        <Typography
                                            component={item.variant === "routerLink" ? Link : "a"}
                                            to={item.variant === "routerLink" ? item.to : undefined}
                                            target={item.variant === "pdfLink" ? "_blank" : undefined}
                                            href={item.variant === "pdfLink" ? item.pdfItem : undefined}
                                            variant="body1"
                                            color="textPrimary"
                                            sx={{ textDecoration: 'none', fontSize: '15px' }}
                                        >
                                            {item.label}
                                        </Typography>
                                    </StyledListLinkWrapper>
                                    {item.subLabel && (
                                        <Typography
                                            component={Link}
                                            to={item?.to || ''}
                                            variant="caption"
                                            color="textPrimary"
                                            sx={{ textDecoration: 'none', fontSize: '15px' }}
                                        >
                                            <Box fontSize=".7rem">{item.subLabel}</Box>
                                        </Typography>
                                    )}
                                    {item.renderBottomLine && (
                                        <Box pt={3}>
                                            <Divider/>
                                        </Box>
                                    )}
                                </StyledListItem>
                            ))}
                    </List>
                </Box>
            </StyledDrawerContentWrapper>
        </StyledDrawer>
    );
};

export default DashboardSidebarBar;
