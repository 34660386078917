import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    TextField,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import {useAppDispatch, useAppSelector} from "commons/store/hooks";
import ColoredBox from "components/ColoredBox";
import Table from "components/Table";
import React, { useEffect, useState } from "react";
import {provideDrug} from "features/ecrf/redux/ecrfSlice";
import {useSnackbar} from "notistack";
import {ExpandMore} from "@mui/icons-material";
import DateTime from "core/helpers/date/DateTime";
import {selectUserProfile} from "core/redux/userSlice";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import {HalResource} from "types/halResource.types";
import {EcrfEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead} from "../../../../types/api.types";
import {roles} from 'commons/auth/roles';
import {DATE_FORMAT, DATETIME_FORMAT} from "commons/constants/env";
import {useTranslation} from "react-i18next";

const DashboardProvide = () => {
    const dispatch = useAppDispatch();
    const [page, setPage] = useState(1);
    const initialDrugArray: string[] = new Array(100).fill("");
    const [drugNumber, setDrugNumber] = useState(initialDrugArray);
    const {enqueueSnackbar} = useSnackbar();
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState() as any;
    const [selectedIndex, setSelectedIndex] = useState() as any;
    const [showManualDoseValue, setShowManualDoseValue] = useState(false);
    const [manualValue, setManualValue] = useState<string>("");
    const [isDataLoading, setIsDataLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false);
    const userProfile = useAppSelector(selectUserProfile);
    const { t } = useTranslation();

    const [ecrves, setEcrves] = useState<any>({
        itemsPerPage: 0,
        totalItems: 0,
        _embedded: {item: []}
    })

    const fetchData = async () => {
        setIsDataLoading(true)

        try {
            const response = await axiosSecureInstance.get<HalResource<EcrfEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead[]>>
            (`/api/ecrves/drugs/allocation`, {
                headers: {
                    accept: 'application/hal+json',
                },
                params: {
                    pagination: true,
                    page,
                    itemsPerPage: 30,
                },
            });
            setEcrves(response.data)

        } catch (error: any) {
            enqueueSnackbar(error?.message || error?.detail, {variant: 'error'});
        }

        setIsDataLoading(false)
    }

    const displayExportButton: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.monitor.includes(role)
    );

    const isReadOnly: boolean = userProfile.roles.some(
        (role: string) => roles.monitor.includes(role)
    );

    const handleClose = () => {
        setConfirmationModal(false);
        setShowManualDoseValue(false);
        setManualValue("");
        setSelectedRow();
        setSelectedIndex();
    };

    const handleModal = (index: any, row: any) => {
        setConfirmationModal(true);
        setSelectedIndex(index);
        setSelectedRow(row);
    };

    const handleNewValue = (e: any) => {
        if (parseFloat(e.target.value) < 0) setManualValue("0");
        else if (parseFloat(e.target.value) > 50) setManualValue("50");
        else {
            setManualValue(e.target.value);
        }
    };

    const drugProvider = async (row: any, index: number) => {
        if (!row) return;

        /** Structure for standard provide action */
        const drugStandard = [
            {
                number: drugNumber[index],
                drug: row?._embedded?.drugGroup?._embedded?.drugs[0]?._links?.self?.href,
            },
        ];

        /** Structure for corrected dose value */
        const drugCorrect = [
            {
                number: drugNumber[index],
                drug: row?._embedded?.drugGroup?._embedded?.drugs[0]?._links?.self?.href,
                status: 1,
                dose: parseFloat(manualValue),
            },
        ];

        try {
            setIsProcessing(true);
            const drugValues = showManualDoseValue ? drugCorrect : drugStandard;
            await dispatch(provideDrug({elementId: row.id, drugs: drugValues}));
            setDrugNumber(initialDrugArray);
            enqueueSnackbar(`${t('drug-has-been_dispensed-for-patient')} ${row?.randomId}`, {
                variant: "success",
            });
            handleClose();
            setIsProcessing(false);
            await fetchData()
        } catch (error: any) {
            handleClose();
            enqueueSnackbar(error.detail, {variant: 'error'});
        }
    };


    const ecrfStatusClosed = (status: number) => {
        switch (status) {
            case 6:
            case 5:
                return true
            default:
                return false
        }
    }

    useEffect(() => {
        fetchData().then()
    }, [dispatch, page]);

    return (
        <ColoredBox pt={0} px={5} maxWidth="100%" ml="auto" mr="auto">
            <Box display={'flex'} gap={'20px'} justifyContent={'end'}>
                <Box mt={'20px'}>
                    {displayExportButton &&
                        <Button
                            size={'small'}
                            color={'primary'}
                            variant={'outlined'}
                            onClick={() => window.open(
                                `${process.env.REACT_APP_API_URL}/api/ecrves/export.xlsx/drug-allocation`,
                                '_blank'
                                )}>
                            {t('export')}
                        </Button>
                    }
                </Box>
            </Box>

            <Table
                pagination={{
                    pageSize: ecrves?.itemsPerPage,
                    current: page,
                    total: ecrves?.totalItems,
                }}
                onChangePage={(page) => setPage(page)}
                data={ecrves._embedded?.item || []}
                isLoading={isDataLoading}
                tableName={t('drug-allocations')}
                columns={[
                    {
                        title: <Box>{t('randomization-number')}</Box>,
                        render: (row: any) => (
                            <Box
                                p={1}
                                style={{
                                    opacity: ecrfStatusClosed(row?.status) ? '50%' : '100%',
                                    backgroundColor: row?._embedded?.drugs ? "" : "#6BDFE1",
                                    borderRadius: row?._embedded?.drugs ? "" : "5px",
                                    fontWeight: row?._embedded?.drugs ? 400 : 700,
                                    textAlign: "center",
                                }}
                            >
                                {row?.randomId}
                            </Box>
                        ),
                        key: "name",
                    },
                    {
                        title: <Box ml="20px">{t('randomization-date')}</Box>,
                        render: (row: any) => (
                            <Box style={{opacity: ecrfStatusClosed(row?.status) ? '50%' : '100%'}}
                                ml="20px">{DateTime.toIso(row?.randomAt, DATETIME_FORMAT)}</Box>
                        ),
                        key: "randomDate",
                    },
                    {
                        title: (
                            <Box ml="20px" fontWeight="bold">
                                {t('preparation-for-release')}
                            </Box>
                        ),
                        render: (row: any) => <Box style={{opacity: ecrfStatusClosed(row?.status) ? '50%' : '100%'}}
                            ml="20px">{row?.drugAllocation && row?.drugAllocation[0]?.drugName}</Box>,
                        key: "drugName",
                    },
                    {
                        title: <Box ml="20px">{t('preparation-amount')}</Box>,
                        render: (row: any) => (
                            <Box style={{opacity: ecrfStatusClosed(row?.status) ? '50%' : '100%'}} ml="20px">
                                {row?.drugAllocation && row?.drugAllocation[0]?.dose}
                                {row?.drugAllocation && row?.drugAllocation[0]?.unit}
                            </Box>
                        ),
                        key: "drugValue",
                    },
                    {
                        title: <Box ml="20px">{t('body-weight')}</Box>,
                        render: (row: any) => (
                            <Box style={{opacity: ecrfStatusClosed(row?.status) ? '50%' : '100%'}}
                                ml="20px">{row?.currentBodyWeight ? `${row?.currentBodyWeight}g` : ""}</Box>
                        ),
                        key: "bodyWeight",
                    },
                    {
                        title: <Box ml="20px">{t('series-and-number')}</Box>,
                        render: (row: any, index) => (
                            <Box style={{opacity: ecrfStatusClosed(row?.status) ? '50%' : '100%'}} pt={1}
                                pb={1}>
                                <TextField
                                    size="small"
                                    id="outlined-basic"
                                    variant="outlined"
                                    disabled={ecrfStatusClosed(row?.status)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        let drugs: any = drugNumber;
                                        drugs[index] = e.target.value;
                                        setDrugNumber(drugs.slice());
                                    }}
                                    value={drugNumber[index]}
                                />
                            </Box>
                        ),
                        key: "number",
                    },
                    {
                        title: <Box ml="20px">{t('release-date')}</Box>,
                        render: (row: any) => (
                            <Box style={{opacity: ecrfStatusClosed(row?.status) ? '50%' : '100%'}}>
                                {row?._embedded?.drugs ? (
                                    <Box>
                                        <Accordion style={{boxShadow: "unset", background: "none"}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore/>}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography style={{fontWeight: "bold"}}>
                                                    {DateTime.toIso(row?._embedded?.drugs?.at(-1)?.createdAt, DATE_FORMAT)}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography style={{marginTop: "-20px", fontSize: ".9rem"}}>
                                                    {row?._embedded?.drugs?.map((drug: any) => (
                                                        <Box key={drug.id} mb={1}>
                                                            <Box>{DateTime.toIso(drug?.createdAt, DATETIME_FORMAT)}</Box>
                                                            <Box>
                                                                <b>{t('series-and-number')}:</b> {drug?.number}
                                                            </Box>
                                                            <Box>
                                                                <b>{t('released')}:</b> {row?.drugAllocation && row?.drugAllocation[0]?.drugName}
                                                            </Box>
                                                            <Box>
                                                                <b>{t('released-by')}:</b> {drug?._embedded?.owner?.lastname}
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                ) : (
                                    ""
                                )}
                            </Box>
                        ),
                        key: "dateOfProvide",
                    },
                    {
                        title: <Box ml="20px">{t('actions')}</Box>,
                        noWrap: true,
                        render: (row: any, index) => (
                            <Box style={{opacity: ecrfStatusClosed(row?.status) ? '50%' : '100%'}} ml="20px">
                                <Button
                                    type="button"
                                    onClick={() => handleModal(index, row)}
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    style={{width: "100%"}}
                                    disabled={drugNumber[index].length < 3 || isReadOnly}
                                >
                                    {row?._embedded?.drugs ? t('dispense-again') : t('dispense-dose')}
                                </Button>
                            </Box>
                        ),
                        key: "actions",
                    },
                ]}
            />

            {/** Modal with confirmation action, uses params from state */}

            <Dialog open={confirmationModal} onClose={handleClose} fullWidth>
                <DialogTitle style={{opacity: showManualDoseValue ? ".3" : "1"}}>
                    {t('do-you confirm-that-the-calculated-dose-is-correct') + " "}
                    {selectedRow?.drugAllocation[0]?.saline === 3 ? "(3ml)" : "(0,15mg/kg masy ciała)"}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {showManualDoseValue && (
                            <Box
                                alignItems="center"
                                display="flex"
                                color="black"
                                fontWeight="bold"
                                fontSize="20px"
                            >
                                <Box mr={2} fontSize="18px">
                                    {t('give-the-correct-dose')}:
                                </Box>
                                <TextField
                                    onChange={handleNewValue}
                                    size="small"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={manualValue}
                                    type="number"
                                />
                                <Box ml={2}>{selectedRow?.drugAllocation[0]?.unit}</Box>
                            </Box>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {showManualDoseValue && (
                        <Button onClick={handleClose} color="primary">
                            {t('cancel')}
                        </Button>
                    )}

                    {!showManualDoseValue && (
                        <Button
                            onClick={() => setShowManualDoseValue(true)}
                            color="secondary"
                            variant="contained"
                        >
                            {t('no')}
                        </Button>
                    )}

                    <Button
                        onClick={() => drugProvider(selectedRow, selectedIndex)}
                        color="primary"
                        autoFocus
                        variant="contained"
                        disabled={isProcessing}
                    >
                        {isProcessing ? (
                            <CircularProgress size={22}/>
                        ) : (
                            <Box>{showManualDoseValue ? t('spend') : t('yes')}</Box>
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </ColoredBox>
    );
};

export default DashboardProvide;
