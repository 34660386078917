import {
  configureStore,
  ThunkAction,
  Action,
  AnyAction,
  combineReducers,
  Reducer,
} from "@reduxjs/toolkit";
import coreReducer from "core/redux/index";
import usersFeatureReducer from "features/users/redux/index";
import medicalCentersFeatureReducer from "features/medicalCenters/redux/index";
import dashboardFeatureReducer from "features/dashboard/redux/index";
import ecrfFeatureReducer from "features/ecrf/redux/index";
import fileFeatureReducer from "features/documentation-management/redux/index";
import drugManagementFeatureReducer from "features/drugs-management/redux/index";
import drugsFeatureReducer from "features/drugs/redux/index";
import commentsFeatureReducer from "features/comments/redux/index";
import notificationsReducer from "features/notifications/redux/index"
import { Middleware } from "@reduxjs/toolkit";

export const throwMiddleware: Middleware = () => (next) => (action) => {
  next(action);
  if (action?.error) {
    throw action.payload;
  }
};

const combinedReducer = combineReducers({
  core: coreReducer,
  users: usersFeatureReducer,
  medicalCenters: medicalCentersFeatureReducer,
  dashboard: dashboardFeatureReducer,
  ecrf: ecrfFeatureReducer,
  file: fileFeatureReducer,
  drugManagement: drugManagementFeatureReducer,
  drugs: drugsFeatureReducer,
  comments: commentsFeatureReducer,
  notifications: notificationsReducer
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "user/logout") {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(throwMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
