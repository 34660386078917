import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { getToken, getRefreshToken, saveToken } from 'commons/auth/tokens';
import { API_URL } from 'commons/constants/env';
import { ROUTES_CORE } from 'commons/constants/paths';
import { useNavigate } from 'react-router-dom';

const applyAppTokenInterceptor = (axiosClient: AxiosInstance, navigate: any) => {
    axiosClient.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
        const accessToken = getToken();
        config.headers.Authorization = `Bearer ${accessToken}`;

        return Promise.resolve(config);
    });

    axiosClient.interceptors.response.use(
        async response => response,
        async error => {
            const originalRequest = error.config;
            switch (error.response?.status) {
                case 401:
                case 403:
                    if (error.response.status === 401 && !originalRequest._retry) {
                        originalRequest._retry = true;
                        const refreshToken = getRefreshToken();
                        if (refreshToken) {
                            try {
                                const response = await axios.post(`${API_URL}/token/refresh`, {refreshToken});
                                const accessToken = response.data.accessToken;

                                saveToken(accessToken);
                                originalRequest.headers.Authorization = `Bearer ${accessToken}`;

                                return axios(originalRequest);
                            } catch (error) {
                                return Promise.reject(error);
                            }
                        }
                    }

                    navigate(ROUTES_CORE.LOGOUT);
                    break;
                case 404:
                    navigate('/404');
                    break;
            }

            return Promise.reject(error);
        }
    );
};

const axiosSecureInstance = axios.create({
    baseURL: API_URL,
});
axiosSecureInstance.defaults.headers.Accept = 'application/json';

applyAppTokenInterceptor(axiosSecureInstance, useNavigate);

export default axiosSecureInstance;
